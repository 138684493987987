import dateFormat, { masks } from "dateformat";

export const formatDate = (date, format) => {
  return dateFormat(date, format);
}

export const showDate = (date) => {
  if(!date){
    return '';
  }
  if(typeof date.getMonth !== 'function'){
    date = new Date(Date.parse(date));
  }
  return formatDate(date, 'dd mmm yyyy');
}

export const showDateTime = (date) => {
  if(!date){
    return '';
  }
  if(typeof date.getMonth !== 'function'){
    date = new Date(Date.parse(date));
  }
  return formatDate(date, 'dd mmm yyyy HH:MM') + ' UTC';
}

export const showAddress = (address) => {
  return address;
}

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
}

export const showNumber = (number) => {
  return number;
}

export const showPercent = (value, precision = 4) => {
  return '' + (value*100).toFixed(precision) + '%';
}

export const showStrikePrice = (amount, currency) => {
  return showPrice(amount, currency);
  // return '' + amount + ' ' + currency?.toUpperCase();
}

export const roundDownAmount = (amount, precision) => {
  let factor = Math.pow(10, precision);
  const roundDownValue =  Math.floor(amount * factor) / factor;
console.log("roundDownValue",amount, roundDownValue)
  let value = '' + roundDownValue;
  var parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if(parts.length > 1){
    let count = 1;
    for(let i = 2; i < 8; i ++){
      if(parts[1][i] != '0'){
        count = i;
      }
    }
    parts[1] = parts[1].substring(0, count+1);
  }
  return parts.join(".");
  
};

export const showAmount = (amount, currency = null, precision = 8, isMenu = false,) => {
  let amountToFixed =  Number(amount).toFixed(precision);

  if(currency && (currency.toLowerCase() === "usdt" || currency.toLowerCase() === "usdc") && isMenu){
    amountToFixed = Number(amount).toFixed(2)
  }
  let value = '' + amountToFixed;
  var parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if(parts.length > 1){
    let count = 1;
    for(let i = 2; i < 8; i ++){
      if(parts[1][i] != '0'){
        count = i;
      }
    }
    parts[1] = parts[1].substring(0, count+1);
  }
  return parts.join(".");
}

export const showPrice = (amount, currency = null, precision = 8) => {
  if (currency)
    return showAmount(amount, currency, precision) + ' ' + currency?.toUpperCase();
  else
    return showAmount(amount, currency, precision);
  // if(currency?.toLowerCase() == 'usd' || currency?.toLowerCase() == 'usdc'){
  //   return showAmount(amount, currency) + ' ' + currency?.toUpperCase();
  // }
  // else{
  //   return '' + amount.toFixed(8) + ' ' + currency?.toUpperCase();
  // }
}

export const showOption = (value, options) => {
  for(const op of options){
    if(op.value == value){
      return op.label;
    }
  }
  return null;
}

export const toDate = (dateStr) => {
  if(!dateStr || dateStr == ''){
    return null;
  }

  return new Date(Date.parse(dateStr));
}

export const fromDate = (date) => {
  if(!date){
    return '';
  }
  return '' + date.getFullYear() + '-' + (date.getMonth()+1).toString().padStart(2, '0')  + '-' + date.getDate().toString().padStart(2, '0'); 
}

export const ageFromDate = (dob) => {
  const month_diff = Date.now() - dob.getTime();
  const age_dt = new Date(month_diff); 
  const year = age_dt.getUTCFullYear();
  return Math.abs(year - 1970);
}

export const toUsdc = (currency) => {
  if(!currency){
    return currency;
  }
  if(currency.toLowerCase() == 'usd' || currency.toLowerCase() == 'usdc'){
    return 'USDC';
  }
  return currency.toUpperCase();
}

