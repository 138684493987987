import {showPercent, roundDownAmount } from "../../libs/util";

const Component = ({product, currency, expiredAmount,  daysLeft, productAPR}) => {

   const getCurrency = (currency) => {
    if(!currency){
      return currency;
    }
    return currency.toUpperCase();
  }

    return (
      <>
        <div className='p-2'>
          <div className='nk-ivp-summary card-text'>
            <div className='row'>
              <div className='col-6 border-right'>
                <span className='lead-text'>
                  <span className=' text-dark extra-dark'>{product ? (productAPR ? `${productAPR}%` :showPercent(product.calc_adj_apy)) : '-'}</span>
                </span>
                <span className='sub-text'>Annualised Return (APR)</span>
              </div>
              <div className='col-6'>
                <span className='lead-text'>
                  <span className='text-dark extra-dark'>{product && daysLeft ? daysLeft : '-'}</span>
                </span>
                <span className='sub-text'>Days</span>
              </div>
            </div>
            <div className='card-title text-center pt-2 mt-2 border-top'>
              <span className='title form-label'>Indicative Return on Expiry Date</span>
            </div>
            <div className='row'>
              <div className='col-12 '>
                <span className='lead-text'>
                  <span className=' text-dark extra-small'>{expiredAmount ? roundDownAmount(expiredAmount, 2) : 0}</span>
                </span>
                <span className='lead-text'>
                  <span className=' text-dark extra-small'>{currency ? getCurrency(currency) : '-'}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Component;
